import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './api/index'
import i18n from './i18n/index'
import { sensors,abtest } from '@/plugins/sensors'
// import 'amfe-flexible'
import md5 from 'js-md5'
import { Toast } from 'vant';
import '/src/assets/script/onelinkGenerator'
// import VConsole from 'vconsole'
// import Video from 'video.js'
// import 'video.js/dist/video-js.css'
// Vue.prototype.$video = Video
import '@unocss/reset/tailwind.css'
import 'uno.css'
import '@/assets/main.css'

Toast.setDefaultOptions({ duration: 3500, position: 'top' });
Vue.use(Toast);

Vue.prototype.$sensors = sensors
Vue.prototype.$abtest = abtest
Vue.prototype.$http = http
Vue.prototype.$md5 = md5
Vue.prototype.$fbq = function () {
  if (process.env.VUE_APP_MODE == 'production') {
    window.fbq(...arguments)
  }
}
// if (process.env.NODE_ENV !== 'production') {
//   new VConsole()
// }

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app')
