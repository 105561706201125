<template>
  <div class="header_wrapper" :class="`${bgWhite ? 'bg-white':''} transition ${headerClass}`">
    <div class="header">
      <div class="part left">
        <img v-if="$store.state.header.canBack" @click="$router.back(-1)" src="@/assets/img/nav_back_icon.png">
      </div>
      <div class="part center">
        <p
          v-if="typeof $store.state.header.title === 'string'"
          class="fs-18px fw-400 font-Line"
        >{{ $store.state.header.title }}</p>
        <img v-else src="@/assets/logo.svg">
      </div>
      <div v-if="$store.state.header.showPop" class="part right">
        <img @click="showPop = true" src="@/assets/img/nav_more_icon.png">
      </div>
      <van-popup v-if="$store.state.header.showPop" closeable close-icon-position="top-left" v-model="showPop" position="right" :style="{ height: '100%', width: '300px' }">
        <div class="select-right flex flex-col gap-20px">
          <div @click="toContact" class="slectItem">Contact Us</div>
          <a href="https://s3.bongmi.com/miscs/face-yoga/en/service.html" class="slectItem">Terms of Service</a>
          <a href="https://s3.bongmi.com/miscs/face-yoga/en/privacy.html" class="slectItem">Privacy Policy</a>
        </div>
      </van-popup>
    </div>
    <div v-if="$store.state.progress.show && $store.state.header.show" class="px-20px py-10px">
      <Progress />
    </div>
    <div v-if="$store.state.progress2.show && $store.state.header.show" class="px-20px py-20px">
      <Progress2 />
    </div>
  </div>

</template>

<script>
import Vue from 'vue';
import { Popup } from 'vant';
import Progress from "@/components/Progress.vue";
import Progress2 from "@/components/Progress2.vue";

Vue.use(Popup);
export default {
  components: {
    Progress,
    Progress2
  },
  name: "Header",
  data () {
    return {
      showPop: false,
      bgWhite: false
    }
  },
  mounted() {
    document.addEventListener('scroll', this.scrollFunc)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollFunc)
  },
  props: {
    canBack: Boolean,
    background: String
  },
  computed:{
    headerClass(){
      return this.$store.state.header.show? '': 'headerHide'
    }
  },
  methods: {
    scrollFunc() {
      this.bgWhite = !!window.scrollY
    },
    toContact () {
      this.$router.push({ name : 'ContactUs' })
      this.showPop = false
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header_wrapper {
  z-index: 110;
  top: 0;
  // transition: top .3s;
  position: fixed;
  // background-color: var(--theme_color);
  width: 100%;
  .header {
    width: 100%;
    height: var(--header_height);
    display: flex;
    align-items: center;
    .left{
      width: 30%;
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;
      img{
        width: 29px;
        height: 29px;
      }
    }
    .center{
      width: 40%;
      display: flex;
      justify-content: center;
      img{
        width: auto;
        height: 28px;
      }
    }
    .right{
      width: 30%;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      img{
        width: 29px;
        height: 29px;
      }
    }
  }
}
.headerHide {
  top: -100%;
}
.select-right {
  padding-top: 79px;
  padding-left: 20px;
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .slectItem {
    height: 25px;
    font-size: 20px;
    font-family: Quicksand-Medium, Quicksand;
    font-weight: 500;
    color: #2D2D2D;
    line-height: 25px;
  }
}
::v-deep .van-popup__close-icon {
  color: #2D2D2D;
}
</style>
