import Vue from 'vue'
import Vuex from 'vuex'
import lscache from 'lscache'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import http from '@/api/index'
import md5 from 'js-md5'

Vue.use(Vuex)

// 修改名称可以更新用户缓存
const web2appDataName = 'web2appData-1.6.0'
const web2appData = lscache.get(web2appDataName)
// 运行环境
const envModeMap = {
  development: 'test',
  test: 'test',
  'pre-release': 'live',
  production: 'live',
}

export default new Vuex.Store({
  state() {
    return web2appData
      ? web2appData
      : {
          API_MODE: process.env.VUE_APP_MODE
                    ? envModeMap[process.env.VUE_APP_MODE]
                    : 'test',
          header: {
            show: true,
            title: undefined,
            canBack: true,
            showPop: true
          },
          progress: {
            show: false,
            total: 1,
            current: 1,
          },
          progress2: {
            show: false,
            total: 4,
            current: 0,
          },
          fy_web_register_abtest: 'Other',
          themeColor: 'grey',
          progressPercent: 0,
          email: '',
          name: '',
          password: md5('123456bm'),
          visitorId: '',
          visitorUserId: 0,
          faceYogaEffect: [],
          faceYogaArea: [],
          onelink: '',
          fbp: '',
          fbc: '',
          payStatus: false,
          // 注册流程 版本1 用户问卷 的回答
          questions: {
          },
          // 注册流程 版本2 用户问卷 的回答
          registProcess02Questions: {
          },
          authorization: '',
          isFromEmail: false,
        }
  },
  getters: {
    userAge: (state) => {
      if (state.registProcess02Questions.question16) {
        return +new Date().getFullYear() - new Date(state.registProcess02Questions.question16).getFullYear()
      } else {
        return 30
      }
    },
    API_HOST: (state) => {
      return state.API_MODE === 'live' ? 'api.femometer.com': 'api-staging.bongmi.com'
    },

  },
  mutations: {
    changeStoreStatus(state, { key, value }) {
      if (typeof state[key] === 'object') {
        state[key] = {
          ...state[key],
          ...value,
        }
      } else {
        state[key] = value
      }
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
    changeProgressStatus(state, { key, value }) {
      state.progress[key] = value
    },
    changeHeaderStatus(state, { key, value }) {
      state.header[key] = value
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
    changeThemeColor(state, { value }) {
      state.themeColor = value
    },
    changeProgressPercent(state, { value }) {
      state.progressPercent = value
    },
    updateEmail(state, data) {
      state.email = data
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
    updateEffect(state, data) {
      state.faceYogaEffect = data
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
    updateArea(state, data) {
      state.faceYogaArea = data
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
    setOneLink(state, { url }) {
      state.onelink = url
    },
    setAfSub(state, { fbp, fbc }) {
      state.fbp = fbp
      state.fbc = fbc
    },
    updataPayStatus(state) {
      state.payStatus = true
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
    updateVisitorId(state, { visitorId }){
      state.visitorId = visitorId
    },
    updateVisitorUserId(state, { visitorUserId }){
      state.visitorUserId = visitorUserId
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
    changeQuestionAnswer(state, { key, value }) {
      state.questions[key] = value
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
    changeQuestion2Answer(state, { key, value }) {
      state.registProcess02Questions[key] = value
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
    setAuthorization(state, { value }) {
      state.authorization = value
      lscache.set(web2appDataName, state, 60 * 24 * 30)
    },
  },
  actions: {
    /**
     * Retrieves the visitor identifier asynchronously.
     *
     * @param {Object} commit - The commit function used to update the state.
     * @return {string} The visitor identifier.
     */
    async getVisitorId ({ commit, state }) {
      if (state.visitorId) {
        return state.visitorId
      }
      // Get the visitor identifier.
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      // This is the visitor identifier:
      // const visitorId = `web2app-${result.visitorId}`
      const visitorId = `web2app-${result.visitorId}${new Date().getTime().toString().slice(-7)}`
      // const visitorId = `web2app-5006437027c128c9e28881235123127`
      commit({
        type: 'updateVisitorId',
        visitorId: visitorId
      })
      return visitorId
    },
    /**
     * Retrieves the visitor user ID.
     *
     * @param {Object} commit - The Vuex commit object.
     * @param {Object} state - The Vuex state object.
     * @return {Promise<number>} The visitor user ID.
     */
    async getVisitorUserId ({ commit, state }) {
      if (state.visitorUserId) {
        return state.visitorUserId
      }
      let visitorUserId = 0
      // Get user id
      const userInfoReq = await http({
        method: 'GET',
        url: `/v1/user_location?temporary_id=${state.visitorId}`,
      })
      visitorUserId = userInfoReq.data.userId
      if (!visitorUserId) {
        visitorUserId = await http({
          method: 'post',
          url: `/v1/user`,
          data: {
            temporaryId: state.visitorId,
            password: state.password || md5('123456bm'),
            registerTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }
        }).then(res => {
          return res.data.userId
        })
      }
      commit({
        type: 'updateVisitorUserId',
        visitorUserId: visitorUserId
      })
      return visitorUserId
    },
    /**
     * Retrieves the token from the server.
     *
     * @param {Object} commit - The commit function from Vuex.
     * @param {Object} state - The state object from Vuex.
     * @return {Promise} A Promise that resolves to the token value.
     */
    getToken ({ commit, state }) {
      return (state.visitorUserId
        ? http({
          method: 'GET',
          url: `/v1/web_access_token_by_password?user_id=${state.visitorUserId}&password=${
            state.password || md5('123456bm')
          }`,
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(res => {
            commit({
              type: 'setAuthorization',
              value: res.data.token
            })
            return res.data.token
          }).catch(() => {
            commit({
              type: 'setAuthorization',
              value: ''
            })
            return ''
          })
        : Promise.resolve())
    },
    /**
     * Binds an email to the user's account.
     *
     * @param {object} param0 - An object containing the following properties:
     *   - commit: A function used to commit a mutation in the Vuex store.
     *   - dispatch: A function used to dispatch an action in the Vuex store.
     *   - state: The current state of the Vuex store.
     * @param {object} param1 - An object containing the following properties:
     *   - email: The email to bind to the user's account.
     * @return {Promise} A promise that resolves with the result of the API call.
     */
    bindEmail ({ commit, dispatch, state }, { email }) {
      return http({
        method: 'PUT',
        url: `/v1/user/${state.visitorUserId}/temporary/bind`,
        Headers: {
          'Content-Type': 'application/json'
        },
        data: {
          email: email,
          fromCollectEmail: true
        }
      }).then(res => {
        if (res.data?.password) {
          commit({
            type: 'changeStoreStatus',
            key: 'password',
            value: res.data.password
          })
          commit({
            type: 'changeStoreStatus',
            key: 'authorization',
            value: ''
          })
          return dispatch('getToken')
        }
      })
    }

  },
  modules: {},
})
