import axios from 'axios'
import store from '@/store'
import i18n from '@/i18n'
import { Toast } from 'vant';
const envModeMap = {
  test: 'test',
  'pre-release': 'live',
  production: 'live',
}


// CN_QINGCLOUD(1, "api.bongmi.com"), //userId <= 300000000
// US_AWS(2, "api-us.femometer.com"), //300000000 <= userId <= 600000000
// EU_AWS(3, "api-eu.femometer.com") // userId >= 600000000

const EnumRegionAPI = {
  UNIVERSAL: 'https://api.femometer.com',
  CN_QINGCLOUD: 'https://api.bongmi.com',
  US_AWS: 'https://api-us.femometer.com',
  EU_AWS: 'https://api-eu.femometer.com',
}

function getUserRegionAPI(userId) {
  if (typeof userId !== 'number')
    return EnumRegionAPI.UNIVERSAL

  if (userId <= 0)
    return EnumRegionAPI.UNIVERSAL

  else if (userId <= 300000000)
    return EnumRegionAPI.CN_QINGCLOUD

  else if (userId >= 300000000 && userId <= 600000000)
    return EnumRegionAPI.US_AWS

  else
    return EnumRegionAPI.EU_AWS
}


const langIDMap = {
  'zh': 1,
  'en': 2,
  'tr': 3,
  'de': 4,
  'ja': 5,
  'fr': 6,
  'it': 7,
  'es': 8,
  'ru': 9,
  'id': 10,
  'pt': 11,
  'nl': 12,
  'enGB': 13,
  'tc': 14,
  'kr': 15,
};

const axiosInstance = axios.create({
  timeout: 10000,
  params: {
    
  }
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    if (config.url[0] === '/') {

      // 每次发送请求之前判断是否存在token
      // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况，此处token一般是用户完成登录后储存到localstorage里的
      store.state.authorization && (config.headers.Authorization = `Lollypop-V2:${store.state.authorization}`)
      config.params.language = langIDMap[i18n.locale] || langIDMap.en
      config.params.app_flag = 128
      config.params.platform_type = 5
      config.baseURL = envModeMap[process.env.VUE_APP_MODE] === 'live'
        ? getUserRegionAPI(store.state.visitorUserId)
        : 'https://api-staging.bongmi.com'

    } 
    return config
  },
  error => {
    return Promise.error(error)
  })
// 响应拦截器
axiosInstance.interceptors.response.use(response => {
  // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
  // 否则的话抛出错误
  if (response.status === 200) {
    if (response.data.code === 511) {
      // 未授权调取授权接口
    } else if (response.data.code === 510) {
      // 未登录跳转登录页
    } else {
      return Promise.resolve(response)
    }
  } else {
    return Promise.reject(response)
  }
}, error => {
  if (error.message === "Network Error") {
    Toast(i18n.t('network_error'))
    return Promise.reject(error)
  }
  // 我们可以在这里对异常状态作统一处理
  if (error?.response?.status) {
    // 处理请求失败的情况
    // 对不同返回码对相应处理
    return Promise.reject(error.response)
  }
})

export default axiosInstance
