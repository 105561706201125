<template>
  <div class="progress flex gap-x-11px">
    <div
      v-for="i in $store.state.progress.total"
      :key="i"
      :class="i <= $store.state.progress.current? 'active': 'inactive'"
      class="h-6px rounded-full flex-1"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Progress",
  props:{
    percent: Number
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.active {
  background: #AC8BFF;
}
.inactive {
  background: rgba(108, 97, 181, 0.1);
}
</style>
