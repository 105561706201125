import store from '@/store'

export default function (to, from, next) {
  store.commit({
    type: 'changeThemeColor',
    value: 'white'
  })
  store.commit({
    type: 'changeStoreStatus',
    key: 'progress',
    value: {
      show: false,
      total: 2,
      current: 2
    }
  })
  store.commit({
    type: 'changeStoreStatus',
    key: 'header',
    value: {
      canBack: true,
      showPop: false,
      show: true,
      title: ''
    }
  })


  if(store.state.email) {
    return next('/home')
  } else if(!store.state.visitorUserId && !to.path.includes('welcome')){
    return next('/welcome')
  } else {
    return next();
  }
}