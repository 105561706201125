import onelink_smart from './onelink-smart-script'
import store from '/src/store'

start()
function start () {
  waitCookie().then(() => {
    onelink_smart()

    const baseUrl = 'https://fy.onelink.me/9uCF/ejv1tp8j'

    const onelinkGenerator = new window.AF.OneLinkUrlGenerator({
      oneLinkURL: baseUrl,
      pidKeysList: ['pid', 'network'],
      campaignKeysList: ['c', 'campaign_name'],
      campaignStaticValue: 'none',
      skipList: [],
    })

    let fbp = document.cookie
      .split(';')
      .filter((c) => c.includes('_fbp='))
      .map((c) => c.split('_fbp=')[1])
    let fbc = document.cookie
      .split(';')
      .filter((c) => c.includes('_fbc='))
      .map((c) => c.split('_fbc=')[1])
    fbp = (fbp.length && fbp[0]) || null
    fbc = (fbc.length && fbc[0]) || null

    if (!fbc && window.location.search.includes('fbclid=')) {
      fbc = 'fb.1.' + +new Date() + '.' + window.location.search.split('fbclid=')[1]
    }

    onelinkGenerator.setAdset('adset_name', 'af_adset')
    onelinkGenerator.setAd('ad_name', 'af_ad')
    onelinkGenerator.setCustomParameter('af_prt', 'af_prt')
    onelinkGenerator.setAfSub1('fbclid')
    onelinkGenerator.setAfSub2('fbc', fbc)
    onelinkGenerator.setAfSub3('fbp', fbp)
    onelinkGenerator.setAfSub4('ua', window.navigator.userAgent)

    const url = onelinkGenerator.generateUrl()
      ? onelinkGenerator.generateUrl()
      : baseUrl

    store.commit({
      type: 'setOneLink',
      url: url,
    })
    store.commit({
      type: 'setAfSub',
      fbp,
      fbc,
    })
  })
}


function waitCookie() {
  return new Promise((resolve) => {
    let fbp = document.cookie
      .split(';')
      .filter((c) => c.includes('_fbp='))
      .map((c) => c.split('_fbp=')[1])
    let fbc = document.cookie
      .split(';')
      .filter((c) => c.includes('_fbc='))
      .map((c) => c.split('_fbc=')[1])
    fbp = (fbp.length && fbp[0]) || null
    fbc = (fbc.length && fbc[0]) || null

    if (fbp || fbc) {
      resolve(fbp || fbc);
    } else {
      setTimeout(() => {
        resolve(waitCookie());
      }, 1000);
    }
  });
}
