<template>
  <div id="app" :class="themeColor" class="w-full flex flex-col items-center overflow-hidden">
    <Header />
    <div class="home_bg max-w-450px w-full">
      <!-- <keep-alive :include="/Question/"> -->
        <router-view :key="$route.fullPath"></router-view> 
      <!-- </keep-alive> -->
    </div>

  </div>
</template>

<script>
import Header from "@/components/Header.vue";

function bindBack() {
  history.pushState(null, null, document.URL);
}

export default {
  components: {
    Header,
  },
  mounted() {
    document.body.className = this.$store.state.themeColor
    this.getVisitorId()
    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    function getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
          return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }

      return "unknown";
    }
    this.$sensors.registerPage({
      appName: 'FaceYoga',
      platformType: 'web',
      language: this.$i18n.locale,
      localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      phoneType: getMobileOperatingSystem()
    })

  },
  methods: {
    async getVisitorId(){
      await this.$store.dispatch('getVisitorId')
      await this.$store.dispatch('getVisitorUserId')
      await this.$store.dispatch('getToken')
      if (this.$route.query.userId) {
        this.$sensors.login(this.$route.query.userId)
      } else {
        this.$sensors.login(String(this.$store.state.visitorUserId))
      }
    },
  },
  computed:{
    themeColor(){
      return this.$store.state.themeColor
    },
    canBack(){
      return this.$store.state.header.canBack
    },
    hasProgress(){
      return this.$store.state.progress.show
      // if(this.$route.meta.enableProgress){
      // if(this.$route.path.includes('question')){
      //   return true
      // }else{
      //   return false
      // }
    }
  },
  watch:{
    themeColor:{
      handler: function(newVal){
        document.body.className = newVal
      }
    },
    canBack:{
      handler: function(newVal){
        if(!newVal){
          history.pushState(null, null, document.URL);
          window.addEventListener('popstate', bindBack);
        }else{
          window.removeEventListener('popstate', bindBack);
        }
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'Quicksand-Bold';
    src: url('./assets/font/Quicksand-Bold.ttf');
}
@font-face {
    font-family: 'Quicksand-Medium';
    src: url('./assets/font/Quicksand-Medium.ttf');
}
@font-face {
    font-family: 'Quicksand-Regular';
    src: url('./assets/font/Quicksand-Regular.ttf');
}
@font-face {
    font-family: 'Poppins-Bold';
    src: url('./assets/font/Poppins-Bold.otf');
}
@font-face {
    font-family: 'Poppins-Medium';
    src: url('./assets/font/Poppins-Medium.otf');
}
@font-face {
    font-family: 'Poppins-Regular';
    src: url('./assets/font/Poppins-Regular.otf');
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./assets/font/Poppins-SemiBold.otf');
}
@font-face {
    font-family: 'LINESeed';
    font-weight: 400;
    src: url('./assets/font/LINESeedSans_Rg.ttf');
}
@font-face {
    font-family: 'LINESeed';
    font-weight: 700;
    src: url('./assets/font/LINESeedSans_Bd.ttf');
}

body{
  --header_height: 44px;
  --theme_color: #FFF6F2;
  font-weight: normal;
  margin: 0;
  background-color: rgb(252, 249, 255);
  color: #0C1A36;
}

.home_bg {
  background: url(./assets/img/bg_top.webp);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% auto;
}

* {
  scroll-margin-top: var(--header_height);
}

.pink{
  --theme_color: #FFF6F2;
}
.grey{
  --theme_color: #F5F0ED;
}
.white{
  --theme_color: #FFF;
}
.van-toast__text{
  font-family: LINESeed, Quicksand-Medium, Quicksand;
  word-break: keep-all;
}

* {
  font-family: LINESeed, Quicksand;
}

.van-checkbox-group {
  .van-checkbox {
    box-shadow: none !important;
    position: relative;
    background: #fff;
    // border: 1px solid #DDDDDD;
    border: none; /*2*/
    min-height: 80px;

    &[aria-checked="true"] {
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        
        border-radius: 15px; /*1*/
        border: 3px solid transparent; /*2*/
        background: linear-gradient(95deg, #AC8BFF 31%, #8555FD 94%) border-box; /*3*/
        -webkit-mask: /*4*/
          linear-gradient(#fff 0 0) padding-box, 
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor; /*5'*/
                mask-composite: exclude; /*5*/
      }

      .van-icon-success {
        border: none; /*2*/
        background: linear-gradient(95deg, #AC8BFF 31%, #8555FD 94%) border-box; /*3*/
        &::before {
          content: " "!important;
          width: 100%;
          height: 100%;
          background: url(./assets/img/yes.webp);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 60% auto;
        }
      }
    }

  }

  
}
</style>
