<template>
  <div class="progress flex gap-x-11px">
    <div
      v-for="i in $store.state.progress2.total"
      :key="i"
      class="h-6px rounded-full flex-1 inactive"
    >
      <div class="h-6px active rounded-full"
        :style="`width: ${getProgress($store.state.progress2.current - i + 1)}%;`"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Progress",
  props:{
    percent: Number
  },
  methods: {
    getProgress(progress) {
      return ~~(Math.min(Math.max(progress, 0), 1) * 100)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.active {
  background: #AC8BFF;
}
.inactive {
  background: rgba(108, 97, 181, 0.1);
}
</style>
