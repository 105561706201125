import questionMiddleware from './middleware-question.js'
import layout from '@/views/Regist/layout'

export default [
  {
    path: '',
    component: layout,
    children: [
      {
        path: '/welcome',
        name: 'Welcome',
        component: () =>
          import(/* webpackChunkName: "welcome" */ '@/views/Regist/Welcome.vue'),
      },
      {
        path: '/welcomeA',
        name: 'WelcomeA',
        meta: { sensorsName: 'Web_FY_开屏页' },
        component: () =>
          import(/* webpackChunkName: "welcome" */ '@/views/Regist/Welcome-A.vue'),
      },
      {
        path: '/question1',
        name: 'Question1',
        meta: { sensorsName: 'Web_FY_年龄页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question1" */ '@/views/Regist/Question01.vue'),
      },
      {
        path: '/question2',
        name: 'Question2',
        meta: { sensorsName: 'Web_FY_性别选择页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question2" */ '@/views/Regist/Question02.vue'),
      },
      {
        path: '/question3',
        name: 'Question3',
        meta: { sensorsName: 'Web_FY_FocusArea页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question3" */ '@/views/Regist/Question03.vue'),
      },
      {
        path: '/question4',
        name: 'Question4',
        meta: { sensorsName: 'Web_FY_Effect选择页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question4" */ '@/views/Regist/Question04.vue'),
      },
      {
        path: '/question5',
        name: 'Question5',
        meta: { sensorsName: 'Web_FY_目标时间选择页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question5" */ '@/views/Regist/Question05.vue'),
      },
      {
        path: '/question6',
        name: 'Question6',
        meta: { sensorsName: 'Web_FY_效果对比页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question6" */ '@/views/Regist/Question06.vue'),
      },
      {
        path: '/question7',
        name: 'Question7',
        meta: { sensorsName: 'Web_FY_面部护理Routine页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question7" */ '@/views/Regist/Question07.vue'),
      },
      {
        path: '/question8',
        name: 'Question8',
        meta: { sensorsName: 'Web_FY_面部护理Product页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question8" */ '@/views/Regist/Question08.vue'),
      },
      {
        path: '/question9',
        name: 'Question9',
        meta: { sensorsName: 'Web_FY_面部护理Sunscreen页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question9" */ '@/views/Regist/Question09.vue'),
      },
      {
        path: '/question10',
        name: 'Question10',
        meta: { sensorsName: 'Web_FY_面部护理Spend页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question10" */ '@/views/Regist/Question10.vue'),
      },
      {
        path: '/question11',
        name: 'Question11',
        meta: { sensorsName: 'Web_FY_课程效果示意页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question11" */ '@/views/Regist/Question11.vue'),
      },
      {
        path: '/question12',
        name: 'Question12',
        meta: { sensorsName: 'Web_FY_皮肤类型Oily页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question12" */ '@/views/Regist/Question12.vue'),
      },
      {
        path: '/question13',
        name: 'Question13',
        meta: { sensorsName: 'Web_FY_皮肤类型Cleanse页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question13" */ '@/views/Regist/Question13.vue'),
      },
      {
        path: '/question14',
        name: 'Question14',
        meta: { sensorsName: 'Web_FY_皮肤类型Acne页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question14" */ '@/views/Regist/Question14.vue'),
      },
      {
        path: '/question15',
        name: 'Question15',
        meta: { sensorsName: 'Web_FY_皮肤类型UnevenSkin页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question15" */ '@/views/Regist/Question15.vue'),
      },
      {
        path: '/question16',
        name: 'Question16',
        meta: { sensorsName: 'Web_FY_皮肤问题页' },
        component: () =>
          import(/* webpackChunkName: "regist1Question16" */ '@/views/Regist/Question16.vue'),
      },
      {
        path: '/generate_plan',
        name: 'GeneratePlan',
        meta: { sensorsName: 'Web_FY_生成计划页' },
        component: () =>
          import(/* webpackChunkName: "generate_plan" */ '@/views/Regist/GeneratePlan.vue'),
      },
      {
        path: '/plan_ready',
        name: 'PlanReady',
        meta: { sensorsName: 'Web_FY_邮箱填写页' },
        component: () =>
          import(/* webpackChunkName: "plan_ready" */ '@/views/Regist/PlanReady.vue'),
      },
      {
        path: '/regist',
        name: 'Regist',
        component: () =>
          import(/* webpackChunkName: "regist" */ '@/views/Regist/Regist.vue'),
      },
    ].map(item => {
      (item.beforeEnter = questionMiddleware)
      return item
    })
  }
]



