
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.json'
import it from './it.json'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en', // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    en,
    it,
  },
})
export default i18n