import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../views/Login.vue'
import registrationProcess from './registration_process'
// 注册流程 方式 2
import registrationProcess2 from './registration_process_02'
import { sensors } from '@/plugins/sensors'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/welcome', 
  },
  ...registrationProcess,
  ...registrationProcess2,
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    path: '/home-normal',
    name: 'Home-Normal',
    meta: { sensorsName: 'Web_FY_订阅页' },
    component: () =>
      import(/* webpackChunkName: "home-normal" */ '@/views/Home-Normal.vue'),
  },
  {
    path: '/home-a',
    name: 'Home-A',
    meta: { sensorsName: 'Web_FY_订阅页' },
    component: () =>
      import(/* webpackChunkName: "home-A" */ '@/views/Home-A.vue'),
  },
  {
    path: '/home-b',
    name: 'Home-B',
    meta: { sensorsName: 'Web_FY_订阅页' },
    component: () =>
      import(/* webpackChunkName: "home-B" */ '@/views/Home-B.vue'),
  },
  {
    path: '/download',
    name: 'Download',
    meta: { sensorsName: 'Web_FY_下载页' },
    component: () =>
      import(/* webpackChunkName: "download" */ '@/views/Download.vue'),
  },
  {
    path: '/contact_us',
    name: 'ContactUs',
    meta: { sensorsName: 'Web_FY_联系我们页' },
    component: () =>
      import(/* webpackChunkName: "contact_us" */ '@/views/ContactUs.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "test" */ '../views/test.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach((to) => {
  // 用于采集 $pageview 事件。
  sensors?.quick('autoTrack', {
    $screen_name: to.meta?.sensorsName || ''
  })
  window.scrollTo({ top:0,left:0 })
})
export default router
