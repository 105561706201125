<template>
  <router-view></router-view>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  created() {
    this.$store.commit({
      type: 'changeThemeColor',
      value: 'white'
    })
    this.$store.commit({
      type: 'changeStoreStatus',
      key: 'progress',
      value: {
        show: false,
        total: 2,
        current: 1
      }
    })
    this.$store.commit({
      type: 'changeStoreStatus',
      key: 'header',
      value: {
        canBack: true,
        showPop: false,
        show: true,
        title: undefined
      }
    })
  },
  mounted(){
  },
  watch: {
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>

</style>
