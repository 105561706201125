// import questionMiddleware from './middleware-question.js'
import layout from '@/views/Regist02/layout'
// import store from '@/store'
// function question2Middleware (to, from, next) {
//   next()
// }


export default [
  {
    path: '/regist02',
    name: 'regist02',
    component: layout,
    children: [
      {
        path: 'question1',
        name: 'Regist02Question1',
        meta: { sensorsName: 'Web_FY_年龄范围页' },
        component: () => import(/* webpackChunkName: "regist2Question01" */ '@/views/Regist02/Question01.vue'),
      },
      {
        path: 'question2',
        name: 'Regist02Question2',
        meta: { sensorsName: 'Web_FY_了解程度页' },
        component: () => import(/* webpackChunkName: "regist2Question02" */ '@/views/Regist02/Question02.vue'),
      },
      {
        path: 'question3',
        name: 'Regist02Question3',
        meta: { sensorsName: 'Web_FY_面部瑜伽介绍页' },
        component: () => import(/* webpackChunkName: "regist2Question03" */ '@/views/Regist02/Question03.vue'),
      },
      {
        path: 'question4',
        name: 'Regist02Question4',
        meta: { sensorsName: 'Web_FY_皮肤满意度页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question04" */ '@/views/Regist02/Question04.vue'),
      },
      {
        path: 'question5',
        name: 'Regist02Question5',
        meta: { sensorsName: 'Web_FY_面部护理Routine页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question05" */ '@/views/Regist02/Question05.vue'),
      },
      {
        path: 'question6',
        name: 'Regist02Question6',
        meta: { sensorsName: 'Web_FY_面部护理Product页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question06" */ '@/views/Regist02/Question06.vue'),
      },
      {
        path: 'question7',
        name: 'Regist02Question7',
        meta: { sensorsName: 'Web_FY_面部护理Sunscreen页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question07" */ '@/views/Regist02/Question07.vue'),
      },
      {
        path: 'question8',
        name: 'Regist02Question8',
        meta: { sensorsName: 'Web_FY_美容师护肤次数' },
        component: () =>
          import(/* webpackChunkName: "regist2Question08" */ '@/views/Regist02/Question08.vue'),
      },
      {
        path: 'question9',
        name: 'Regist02Question9',
        meta: { sensorsName: 'Web_FY_FY与美容师对比页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question09" */ '@/views/Regist02/Question09.vue'),
      },
      {
        path: 'question10',
        name: 'Regist02Question10',
        meta: { sensorsName: 'Web_FY_饮食频次页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question10" */ '@/views/Regist02/Question10.vue'),
      },
      {
        path: 'question11',
        name: 'Regist02Question11',
        meta: { sensorsName: 'Web_FY_饮食类型页' },
        component: () =>
          import(/* webpackChunkName: "regist2_Question11" */ '@/views/Regist02/Question11.vue'),
      },
      {
        path: 'question12',
        name: 'Regist02Question12',
        meta: { sensorsName: 'Web_FY_甜食频率页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question12" */ '@/views/Regist02/Question12.vue'),
      },
      {
        path: 'question13',
        name: 'Regist02Question13',
        meta: { sensorsName: 'Web_FY_蔬菜频率页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question13" */ '@/views/Regist02/Question13.vue'),
      },
      {
        path: 'question14',
        name: 'Regist02Question14',
        meta: { sensorsName: 'Web_FY_每日喝水页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question14" */ '@/views/Regist02/Question14.vue'),
      },
      {
        path: 'question15',
        name: 'Regist02Question15',
        meta: { sensorsName: 'Web_FY_性别选择页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question15" */ '@/views/Regist02/Question15.vue'),
      },
      {
        path: 'question16',
        name: 'Regist02Question16',
        meta: { sensorsName: 'Web_FY_年龄页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question16" */ '@/views/Regist02/Question16.vue'),
      },
      {
        path: 'question17',
        name: 'Regist02Question17',
        meta: { sensorsName: 'Web_FY_FocusArea页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question17" */ '@/views/Regist02/Question17.vue'),
      },
      {
        path: 'question17-1',
        name: 'Regist02Question17-1',
        meta: { sensorsName: 'Web_FY_Exercise效果页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question17-1" */ '@/views/Regist02/Question17-1.vue'),
      },
      {
        path: 'question18',
        name: 'Regist02Question18',
        meta: { sensorsName: 'Web_FY_皮肤类型页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question18" */ '@/views/Regist02/Question18.vue'),
      },
      {
        path: 'question19',
        name: 'Regist02Question19',
        meta: { sensorsName: 'Web_FY_皮肤问题页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question19" */ '@/views/Regist02/Question19.vue'),
      },
      {
        path: 'question19-1',
        name: 'Regist02Question19-1',
        meta: { sensorsName: 'Web_FY_Wrinkles页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question19-1" */ '@/views/Regist02/Question19-1.vue'),
      },
      {
        path: 'question20',
        name: 'Regist02Question20',
        meta: { sensorsName: 'Web_FY_坚持目的页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question20" */ '@/views/Regist02/Question20.vue'),
      },
      {
        path: 'question20-1',
        name: 'Regist02Question20-1',
        meta: { sensorsName: 'Web_FY_使用效果展示页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question20-1" */ '@/views/Regist02/Question20-1.vue'),
      },
      {
        path: 'question21',
        name: 'Regist02Question21',
        meta: { sensorsName: 'Web_FY_护肤健康状态页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question21" */ '@/views/Regist02/Question21.vue'),
      },
      {
        path: 'question22',
        name: 'Regist02Question22',
        meta: { sensorsName: 'Web_FY_饮食习惯页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question22" */ '@/views/Regist02/Question22.vue'),
      },
      {
        path: 'question23',
        name: 'Regist02Question23',
        meta: { sensorsName: 'Web_FY_花费时间页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question23" */ '@/views/Regist02/Question23.vue'),
      },
      {
        path: 'question24',
        name: 'Regist02Question24',
        meta: { sensorsName: 'Web_FY_面部形状页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question24" */ '@/views/Regist02/Question24.vue'),
      },
      {
        path: 'question25',
        name: 'Regist02Question25',
        meta: { sensorsName: 'Web_FY_SelfEsteem认同度页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question25" */ '@/views/Regist02/Question25.vue'),
      },
      {
        path: 'question26',
        name: 'Regist02Question26',
        meta: { sensorsName: 'Web_FY_CompareOther认同度页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question26" */ '@/views/Regist02/Question26.vue'),
      },
      {
        path: 'question27',
        name: 'Regist02Question27',
        meta: { sensorsName: 'Web_FY_Appearance认同度页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question27" */ '@/views/Regist02/Question27.vue'),
      },
      {
        path: 'question28',
        name: 'Regist02Question28',
        meta: { sensorsName: 'Web_FY_LookOlder认同度页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question28" */ '@/views/Regist02/Question28.vue'),
      },
      {
        path: 'question29',
        name: 'Regist02Question29',
        meta: { sensorsName: 'Web_FY_StayHealthy认同度页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question29" */ '@/views/Regist02/Question29.vue'),
      },
      {
        path: 'question30',
        name: 'Regist02Question30',
        meta: { sensorsName: 'Web_FY_PreliminaryProgress页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question30" */ '@/views/Regist02/Question30.vue'),
      },
      {
        path: 'question30-1',
        name: 'Regist02Question30-1',
        meta: { sensorsName: 'Web_FY_使用效果展示页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question30-1" */ '@/views/Regist02/Question30-1.vue'),
      },
      {
        path: 'question31',
        name: 'Regist02Question31',
        meta: { sensorsName: 'Web_FY_经期页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question31" */ '@/views/Regist02/Question31.vue'),
      },
      {
        path: 'question32',
        name: 'Regist02Question32',
        meta: { sensorsName: 'Web_FY_精神状态页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question32" */ '@/views/Regist02/Question32.vue'),
      },
      {
        path: 'question33',
        name: 'Regist02Question33',
        meta: { sensorsName: 'Web_FY_插画过度页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question33" */ '@/views/Regist02/Question33.vue'),
      },
      {
        path: 'question34',
        name: 'Regist02Question34',
        meta: { sensorsName: 'Web_FY_用户分布页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question34" */ '@/views/Regist02/Question34.vue'),
      },
      {
        path: 'question35',
        name: 'Regist02Question35',
        meta: { sensorsName: 'Web_FY_专家页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question35" */ '@/views/Regist02/Question35.vue'),
      },
      {
        path: 'question36',
        name: 'Regist02Question36',
        meta: { sensorsName: 'Web_FY_科学证明页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question36" */ '@/views/Regist02/Question36.vue'),
      },
      {
        path: 'question37',
        name: 'Regist02Question37',
        meta: { sensorsName: 'Web_FY_GoalConfirm1页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question37" */ '@/views/Regist02/Question37.vue'),
      },
      {
        path: 'question38',
        name: 'Regist02Question38',
        meta: { sensorsName: 'Web_FY_GoalConfirm2页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question38" */ '@/views/Regist02/Question38.vue'),
      },
      {
        path: 'question39',
        name: 'Regist02Question39',
        meta: { sensorsName: 'Web_FY_GoalConfirm3页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question39" */ '@/views/Regist02/Question39.vue'),
      },
      {
        path: 'question40',
        name: 'Regist02Question40',
        meta: { sensorsName: 'Web_FY_Answer分析页' },
        component: () =>
          import(/* webpackChunkName: "regist2Question40" */ '@/views/Regist02/Question40.vue'),
      },
      {
        path: 'plan-ready-1',
        name: 'PlanReady-1',
        meta: { sensorsName: 'Web_FY_邮箱填写页' },
        component: () =>
          import(/* webpackChunkName: "plan-ready-1" */ '@/views/Regist02/PlanReady.vue'),
      },
      {
        path: 'plan-ready-2',
        name: 'PlanReady-2',
        meta: { sensorsName: 'Web_FY_名称页' },
        component: () =>
          import(/* webpackChunkName: "plan-ready-2" */ '@/views/Regist02/PlanReady2.vue'),
      },
    ]
  }
].map(item => {
  // (item.beforeEnter = question2Middleware)
  return item
})
