import '@/assets/script/abtest.min.js'
import pageload from './pageload.es6.js'
export const sensors = require('sa-sdk-javascript')
sensors.init({
  server_url: 'https://sa.bongmi.com/sa?project=default',
  is_track_single_page: false, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: 'beacon',
  heatmap: {
    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'default',
    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'not_collect',
  },
  debug_mode: process.env.VUE_APP_MODE !== 'production',
  show_log: true,
  // show_log: false,
  // show_log: process.env.NODE_ENV !== 'production',
})
sensors.use(pageload,{max_duration:120});
sensors.quick('autoTrack') //用于采集 $pageview 事件。
export const abtest = sensors.use('SensorsABTest', {
  url: 'https://abtest-aws-us-east-01.saas.sensorsdata.com/api/v2/abtest/online/results?project-key=0675F7B571A0D5C1A82B079F8D54B7D1E28627F2'
})
